var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _vm.test
        ? _c(
            "Calendar",
            {
              attrs: {
                "default-month": new Date(_vm.time),
                "start-date": _vm.calendarStartDate,
                "end-date": _vm.calendarEndDate,
                future: false,
              },
              on: { onChange: _vm.onChange },
            },
            [
              _c("template", { slot: "leftContent" }, [
                _c(
                  "div",
                  { staticClass: "header" },
                  [
                    _c("a-button", {
                      staticClass: "topbtn",
                      attrs: { type: "iconBtn", icon: "left" },
                      on: { click: _vm.goBack },
                    }),
                    _c("span", [_vm._v("返回")]),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "rightContent" }, [
                _c(
                  "div",
                  { staticClass: "rightnav" },
                  [
                    _c("CbTableAvater", {
                      attrs: {
                        "avater-name": _vm.staffName,
                        "show-user-name": false,
                        "on-job-id": _vm.staffId,
                        size: "34",
                      },
                    }),
                    _c("span", { staticClass: "thename" }, [
                      _vm._v(_vm._s(_vm.staffName)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "right-rule" },
                      [
                        _c(
                          "a-popover",
                          {
                            attrs: {
                              trigger: "click",
                              visible: _vm.popover,
                              placement: "bottomRight",
                              "overlay-class-name": "check-box",
                              "overlay-style": { width: "380px" },
                            },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                { staticStyle: { padding: "9px 0 10px" } },
                                [_vm._v("考勤规则")]
                              ),
                            ]),
                            _c(
                              "template",
                              { slot: "content" },
                              _vm._l(_vm.attendanceRules, function (item) {
                                return _c(
                                  "div",
                                  { key: item.id, staticClass: "rule-box" },
                                  [
                                    _c("div", { staticClass: "left-box" }, [
                                      _c(
                                        "div",
                                        { staticClass: "left-content" },
                                        [_vm._v(_vm._s(item.ruleName))]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "right-content" },
                                        [_vm._v(_vm._s(item.rulePlan))]
                                      ),
                                    ]),
                                    _c(
                                      "a-button",
                                      {
                                        staticClass: "right-box",
                                        attrs: {
                                          type: "link",
                                          disabled: !item.ruleId,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.view(
                                              item.ruleId,
                                              item.ruleName
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("查看")]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _c(
                              "a-button",
                              {
                                attrs: { type: "default" },
                                on: { click: _vm.button },
                              },
                              [_vm._v(" 考勤规则 ")]
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "personTab" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { prefix: "turn-around" },
                            on: { click: _vm.userTab },
                          },
                          [_vm._v("人员切换")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "bottomContent" }, [
                _c(
                  "div",
                  { staticClass: "content-top" },
                  [
                    _vm.actualDays > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "statistics",
                            class: _vm.active === "" ? "active" : "",
                            attrs: { type: "default" },
                            on: {
                              click: function ($event) {
                                return _vm.buttonTab("", null)
                              },
                            },
                          },
                          [_vm._v(" 出勤 " + _vm._s(_vm.actualDays) + " 天 ")]
                        )
                      : _vm._e(),
                    _vm._l(_vm.attendanceList, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          attrs: { type: "default" },
                          on: {
                            click: function ($event) {
                              return _vm.buttonTab(item.value, item.id)
                            },
                          },
                        },
                        [
                          item.count > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "statistics",
                                  class:
                                    _vm.active === item.value ? "active" : "",
                                },
                                [
                                  _c("span", {
                                    staticClass: "text-color",
                                    style: "backgroundColor:" + item.fontRgba,
                                  }),
                                  _vm._v(" " + _vm._s(item.value) + " "),
                                  _c(
                                    "span",
                                    {
                                      style:
                                        "color:" +
                                        item.fontRgba +
                                        ";padding: 0 5px",
                                    },
                                    [_vm._v(_vm._s(item.count))]
                                  ),
                                  _vm._v(" " + _vm._s(item.unit) + " "),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "content-bottom" },
                  _vm._l(_vm.attendanceTotal, function (item) {
                    return _c("p", { key: item.id }, [
                      _vm._v(" " + _vm._s(item.value) + ": "),
                      _c("span", [_vm._v(_vm._s(item.count))]),
                    ])
                  }),
                  0
                ),
              ]),
              _vm._l(_vm.calendarList.monthDetailVO, function (item) {
                return _c(
                  "template",
                  {
                    staticStyle: { width: "100%", position: "relative" },
                    slot: item.date,
                  },
                  [
                    item.holidayFlag === "1" || item.offdayFlag === "1"
                      ? _c("div", { key: item.data, staticClass: "button" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.holidayFlag === "1" ? "节假" : "休息"
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { key: item.data }, [
                      item.workdayFlag === "1"
                        ? _c("div", { staticClass: "times" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(item.timeList[0].actualInTime || "--")
                              ),
                            ]),
                            _c("span", [_vm._v("—")]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(item.timeList[0].actualOutTime || "--")
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "events",
                          class: { "active-events": _vm.hasThree(item) },
                        },
                        _vm._l(_vm.abnormals, function (col, index) {
                          return _c("div", { key: index }, [
                            item[col.statistics]
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "badge",
                                    style: {
                                      background: col.backgroundColor,
                                      color: col.color,
                                    },
                                  },
                                  [
                                    _c("p", { staticClass: "literals" }, [
                                      _vm._v(_vm._s(item[col.statistics])),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _c(
        "Drawer",
        {
          attrs: {
            title: "人员选择",
            placement: "right",
            closable: "",
            "display-btn": "",
            "is-mask": "",
            visible: _vm.visible,
            size: "small",
          },
          on: { close: _vm.onClose },
        },
        [
          _c("a-input-search", {
            staticStyle: { width: "100%" },
            attrs: { placeholder: "请输入姓名/工号" },
            on: { search: _vm.onSearch },
          }),
          _c(
            "div",
            { staticClass: "staff-box" },
            _vm._l(_vm.userList, function (item) {
              return _c("div", { key: item.onJobId, staticClass: "left" }, [
                _c(
                  "div",
                  {
                    staticClass: "content",
                    on: {
                      click: function ($event) {
                        return _vm.onClick(item)
                      },
                    },
                  },
                  [
                    _c("CbTableAvater", {
                      attrs: {
                        "avater-name": item.staffName,
                        "on-job-id": item.onJobId,
                        "show-user-name": false,
                      },
                    }),
                    _c("p", { attrs: { title: item.staffName } }, [
                      _vm._v(_vm._s(item.staffName)),
                    ]),
                    _c(
                      "span",
                      { staticClass: "num", attrs: { title: item.staffNum } },
                      [_vm._v(_vm._s(item.staffNum))]
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "Drawer",
        {
          attrs: {
            title: _vm.title + "详情",
            placement: "right",
            closable: false,
            "is-mask": "",
            "display-btn": "",
            visible: _vm.detailsVisible,
            size: "small",
          },
          on: { close: _vm.onClose },
        },
        [
          _vm.title === "考勤组"
            ? _c("div", [
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 考勤组名称:"),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.attendanceGroupDetails.attendanceGroupName)
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 考勤组类型:"),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.attendanceGroupDetails.attendanceGroupType === "1"
                          ? "无需打卡"
                          : "需打卡"
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 排班日历:"),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.attendanceGroupDetails.shiftScheduling
                          .shiftSchedulingName
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 出勤日:"),
                  _c("div", { staticClass: "text-box" }, [
                    _vm._v(
                      _vm._s(
                        _vm.attendanceGroupDetails.shiftScheduling
                          .workCycleSequenceStr
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 出勤时间:"),
                  _c("div", { staticClass: "text-box" }, [
                    _vm._v(
                      _vm._s(
                        _vm.attendanceGroupDetails.shiftScheduling
                          .shiftPunchRuleStr
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 核算规则:"),
                  _c("div", { staticClass: "text-box" }, [
                    _vm._v(
                      "考勤规则--" +
                        _vm._s(_vm.attendanceGroupDetails.overTimeRuleName)
                    ),
                    _c("br"),
                    _vm._v(
                      "外勤规则--" +
                        _vm._s(_vm.attendanceGroupDetails.outSideRuleName)
                    ),
                    _c("br"),
                    _vm._v(
                      "补卡规则--" +
                        _vm._s(_vm.attendanceGroupDetails.appendPunchRuleName)
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 适用人员:"),
                  _c("div", { staticClass: "text-box" }, [
                    _vm._v(
                      _vm._s(_vm.attendanceGroupDetails.attendanceGroupStaffStr)
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.title === "考勤日历"
            ? _c("div", [
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 排班日历名称:"),
                  _c("span", [
                    _vm._v(_vm._s(_vm.shiftScheduling.shiftSchedulingName)),
                  ]),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 排班类型:"),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("shiftCycleType")(
                          _vm.shiftScheduling.shiftCycleType
                        )
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 是否同步法定节假安排:"),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.shiftScheduling.syncHolidayFlag ? "是" : "否")
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.title === "加班规则"
            ? _c("div", [
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 规则名称:"),
                  _c("span", [_vm._v(_vm._s(_vm.overtimeRule.ruleName))]),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 补偿方式: "),
                  _c("div", { staticClass: "overtime" }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v(
                        "工作日 -- " +
                          _vm._s(
                            _vm.getCodeContent(
                              _vm.overtimeRule.workdayOvertimeConvertType,
                              _vm.overtime_convert_type
                            )
                          )
                      ),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v(
                        "休息日 -- " +
                          _vm._s(
                            _vm.getCodeContent(
                              _vm.overtimeRule.offdayOvertimeConvertType,
                              _vm.overtime_convert_type
                            )
                          )
                      ),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v(
                        "法定节假日 -- " +
                          _vm._s(
                            _vm.getCodeContent(
                              _vm.overtimeRule.holidayOvertimeConvertType,
                              _vm.overtime_convert_type
                            )
                          )
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 加班时长核算方式: "),
                  _c("div", { staticClass: "overtime" }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v(
                        "工作日 -- " +
                          _vm._s(
                            _vm.getCodeContent(
                              _vm.overtimeRule.workdayOvertimeCalcType,
                              _vm.overtime_calc_type
                            )
                          )
                      ),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v(
                        "休息日 -- " +
                          _vm._s(
                            _vm.getCodeContent(
                              _vm.overtimeRule.offdayOvertimeCalcType,
                              _vm.overtime_calc_type
                            )
                          )
                      ),
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v(
                        "法定节假日 -- " +
                          _vm._s(
                            _vm.getCodeContent(
                              _vm.overtimeRule.holidayOvertimeCalcType,
                              _vm.overtime_calc_type
                            )
                          )
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.title === "补卡规则"
            ? _c("div", [
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 规则名称:"),
                  _c("span", [_vm._v(_vm._s(_vm.punchRule.ruleName))]),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 补卡次数限制:"),
                  _c("span", [
                    _vm._v(_vm._s(_vm.punchRule.maxTimes) + "次/月"),
                  ]),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 补卡时间限制:"),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.getCodeContent(
                          _vm.punchRule.appendPunchLimitType,
                          _vm.appendpunch_limit_type
                        )
                      )
                    ),
                    _c(
                      "cite",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.punchRule.appendPunchLimitType === "2",
                            expression:
                              "punchRule.appendPunchLimitType === '2'",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "，第" +
                            _vm._s(_vm.punchRule.nextMonthMaxDay) +
                            "天内可补卡"
                        ),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.title === "外勤规则"
            ? _c("div", [
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 规则名称:"),
                  _c("span", [_vm._v(_vm._s(_vm.outsideRule.ruleName))]),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 外出申请时间单位:"),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("applicantUnit")(_vm.outsideRule.outsideUnit)
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(" 出差申请时间单位:"),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("applicantUnit")(_vm.outsideRule.travelUnit)
                      )
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }